<template>
  <div>
    <v-chip-group
      v-if="tags.length && !loading"
      active-class="blue white--text"
      :multiple="isMultiple"
      center-active
    >
      <ChipMain
        :disabled="disabled"
        v-for="tag in tags"
        :key="tag.id"
        @selectedTag="selectedTagGroup"
        :tag="tag"
      />
    </v-chip-group>
    <v-skeleton-loader v-else class="d-flex" type="chip@6"></v-skeleton-loader>
  </div>
</template>

<script>
import ChipMain from "@/components/atoms/items/ChipMain.vue";
import AlertMain from "@/components/atoms/AlertMain.vue";

export default {
  components: {
    ChipMain,
    AlertMain,
  },
  props: {
    tags: {
      required: true,
    },
    isMultiple: {
      default: false,
    },
    hasMultipleValues: {
      default: false,
    },
    valor: Boolean,
    disabled: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      tagAdd: null,
      listChips: [],
    };
  },
  methods: {
    selectedTagGroup(e) {
      this.listChips.push(e);

      let unico = this.listChips.filter((elem, index, array) => {
        return index === array.indexOf(elem);
      });

      this.hasMultipleValues
        ? this.$emit(
            "changeTag",
            unico.filter((chip) => chip.selected)
          )
        : this.$emit("changeTag", e);
    },
  },
};
</script>

<style>
  .v-chip--disabled { opacity: 0.85 !important;}
</style>
