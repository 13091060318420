<template>
    <v-col cols="12" :md="cols" :style="!cols ? 'padding: 0' : ''">
      <v-autocomplete v-if="!loading" class="custom-autocomplete"
        hide-details="auto"
        v-model="selected"
        :rules="rules"
        :clearable="clearable"
        :chips="chips"
        :deletable-chips="deletableChips"
        :outlined="outlined"
        :dense="dense"
        :filled="filled"
        :rounded="rounded"
        :items="items"
        :item-text="keyName"
        :item-value="keyValue"
        :placeholder="placeholder"
        :label="label"
        :no-data-text="mensagemItemNaoEncontrado"
        :return-object='retornarObjeto'
        @keyup="pesquisaTermo($event)"
        @change="$emit('change', selected)"
        @blur="onBlur({ clear: true })"
        @click:clear="onClear({ clear: true })"
      >

        <template v-slot:no-data>
          <span class="pa-2 grey--text lighten-1">{{ mensagemItemNaoEncontrado }}</span>
        </template>

        <template
          v-if="comTemplate"
          v-slot:selection="{ item }"
        >
          <v-chip v-if="chips">
            <v-list-item-avatar v-if="exibirAvatar">
              <img :src="gerarAvatar(item, tituloItem)"/>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item[tituloItem] }}
              </v-list-item-title>
            </v-list-item-content>
          </v-chip>

          <v-list-item-content v-else>
            <v-list-item-title>
              {{ item[tituloItem] }}
            </v-list-item-title>
          </v-list-item-content>

        </template>

        <template v-slot:item="{ item }">
          <template>
            <v-list-item-avatar v-if="exibirAvatar">
                <img :src="gerarAvatar(item, tituloItem)" />
              </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item[tituloItem] }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="mascaraDescricao">{{ item[descricaoItem] | VMask(mascaraDescricao) }}</span>
                <span v-else>{{ item[descricaoItem] }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>

        <template v-slot:prepend-inner>
          <v-icon color="grey lighten-1">mdi-{{ firstIcon }}</v-icon>
        </template>

        <template v-slot:append-item>
          <div v-intersect="endIntersect" />
        </template>
      </v-autocomplete>
      <v-skeleton-loader v-else type="text"></v-skeleton-loader>
    </v-col>
</template>

<script>
  export default {
    props: {
      placeholder: String,
      firstIcon: null,
      loading: false,
      chips: Boolean,
      deletableChips: Boolean,
      rounded: Boolean,
      filled: Boolean,
      dense: Boolean,
      outlined: Boolean,
      tituloItem: String,
      mascaraDescricao: String,
      label: String,
      cols: Number,
      mensagemItemNaoEncontrado: String,
      rules: Array,
      clearable: {
        type: Boolean,
        default: true,
      },
      exibirAvatar: {
        type: Boolean,
        default: true,
      },
      descricaoItem: {
        type: String,
        required: false,
      },
      items: {
        required: true
      },
      keyName: {
        default: 'nome'
      },
      keyValue: {
        default: 'id'
      },
      comTemplate: {
        type: Boolean,
        default: true,
        required: false,
      },
      retornarObjeto: {
        type: Boolean,
        default: false,
        required: false,
      },
    },

    data() {
      return {
        text: '',
        itensSelect: [],
        pagina: 0,
        termoPesquisa: '',
        selected: this.valueField || null,
        urlDiceBear: `${process.env.VUE_APP_API_URL_DICEBEAR}`,
      }
    },

    methods: {
      endIntersect(entries, observer, isIntersecting) {
        if (isIntersecting) {
          this.$emit('scrollDown')
        }
      },

      pesquisaTermo(evento) {
        this.$emit(`pesquisa`, evento.target.value)
      },

      onClear(evento) {
        this.$emit('onClear', evento)
      },

      onBlur(evento) {
        this.$emit('onBlur', evento)
      },

      gerarAvatar(item, tituloItem) {
        return `${this.urlDiceBear}?seed=${item[tituloItem]}`;
      }
    },

    watch: {
      valueField: {
        immediate: true,
        handler(newText, oldText) {
          this.selected = newText
        }
      },
      selected(novoValor, valorAntigo) {
        if (novoValor !== valorAntigo) {
          this.$emit('update:value', novoValor)
        }
      }
    }
  }
</script>

<style>
  .custom-autocomplete > div > div:first-child * {
    cursor: pointer;
  }

  .v-list-item__title {
    white-space: break-spaces;
  }
</style>
