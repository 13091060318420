import { render, staticRenderFns } from "./ChipMain.vue?vue&type=template&id=447b2541&"
import script from "./ChipMain.vue?vue&type=script&lang=js&"
export * from "./ChipMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
