<template>
  <v-chip @change="$emit('selectedTag', ownTag)" :disabled="disabled"  @click="ownTag.selected = !ownTag.selected" >
    {{ tag.descricao }}
  </v-chip>
</template>

<script>
export default {
  props: {
    tag: {
      required: true
    },
    disabled: Boolean
  },
  computed: {
    ownTag() {
      return { ...this.tag, selected: false }
    }
  }
}
</script>