<template>
  <div>
    <div v-if="disponibilidadeColaborador">
      <v-date-picker
        v-if="!loading"
        elevation="1"
        v-model="picker"
        color="primary"
        locale="pt"
        full-width
        :events="eventDays"
        @change="getData"
        :reactive="true"
        event-color="green lighten-1"
        :allowed-dates="disableDates"
      ></v-date-picker>
      <v-skeleton-loader v-else type="date-picker-days@2"> </v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import AlertMain from "../AlertMain.vue";

import globalMethods from "../../../mixins/globalMethods.vue";

export default {
  components: {
    AlertMain,
  },
  props: {
    eventDays: Array,
    loading: Boolean,
    editCalendar: Boolean,
    readonly: Boolean,
    disponibilidadeColaborador: {
      type: Array,
    },
  },
  mixins: [globalMethods],
  data() {
    return {
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  methods: {
    disableDates(calendar) {
      if (this.readonly) {
        return true;
      }

      const diaCalendario = new Date(calendar).getDay();
      if (!this.disponibilidadeColaborador.includes(diaCalendario)) {
        return false;
      }

      return calendar >= new Date().toISOString().substr(0, 10);
    },
    setEvents() {
      const d = new Date();
      return d.toISOString().substr(0, 10);
    },
    getData(data) {
      this.$emit("selectedDate", this.dateFormatBR(data));
    },
    emitSwitchState() {
      this.$emit("editCalendar", this.editCalendar);
    },
  },
};
</script>

<style></style>
