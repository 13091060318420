<template>
  <div class="auto-complete">
    <v-autocomplete
      v-if="!loading"
      clearable
      dense
      filled
      hide-details
      rounded
      return-object
      :disabled="disabled"
      :items="data"
      :item-text="itemText"
      :placeholder="placeholder"
      :item-value="itemValue"
      @change="show"
    >
      <template v-slot:no-data>
        <span class="pa-2 grey--text lighten-1">{{ notFound }}</span>
      </template>

      <template v-slot:selection="{ item }">
        <v-chip>
          <v-list-item-avatar>
            <img :src="item.avatar" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.nome }}
            </v-list-item-title>

            <v-list-item-subtitle
              v-if="exibirDetalhes"
              class="font-weight-light"
            >
              {{ item.especializacao }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-chip>
      </template>

      <template v-slot:item="{ item }">
        <template>
          <v-list-item-avatar>
            <img :src="item.avatar" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.nome }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="exibirDetalhes"
              v-html="item.especializacao"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>

      <template class="mr-2 ml-2" v-slot:prepend-inner>
        <v-icon color="grey lighten-1">mdi-{{ firstIcon }}</v-icon>
      </template>
    </v-autocomplete>
    <v-skeleton-loader v-else type="text"></v-skeleton-loader>
  </div>
</template>

<script>

export default {
  props: {
    placeholder: String,
    data: null,
    loading: false,
    disabled: false,
    notFound: { default: "Nada encontrado!" },
    firstIcon: null,
    exibirDetalhes: {
      type: Boolean,
      default: true,
    },
    itemText: {
      default: "nome",
    },
    itemValue: {
      default: "id",
    },
  },
  data() {
    return {
      fieldSelected: null,
    };
  },

  methods: {
    show(e) {
      this.$emit("eventSelected", e);
    },
  },
};
</script>

<style></style>
