<template>
  <h2 class="text-h6 mb-2">{{ title }}</h2>
</template>

<script>
export default {
  props: {
    title: String,
    require: true
  }
}
</script>

<style>
</style>